import '@/styles/globals.scss';
import '@/styles/superstack/base/common.scss';
import { RecoilRoot } from 'recoil';
import useMobileSize from '@/src/utils/hooks/useMobileSize';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.SENTRY_DEBUG === true) {
  Sentry.init({
    dsn: 'https://7c7449e41f53473385994079456b2c6d@o9325.ingest.sentry.io/6616069',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

function ComponentWrapper({ Component, ...pageProps }) {
  const [isMobileSize] = useMobileSize();
  return <Component isMobile={isMobileSize} {...pageProps} />;
}

function MyApp({ Component, pageProps }) {
  return (
    <RecoilRoot>
      <ComponentWrapper Component={Component} {...pageProps} />
    </RecoilRoot>
  );
}

export default MyApp;
