import { atom, useRecoilState } from 'recoil';
import { useEffect } from 'react';

export const mobileState = atom({
  key: 'mobile',
  default: false,
});

const useMobileSize = () => {
  const [isMobileSize, setIsMobileSize] = useRecoilState(mobileState);

  const handleResize = () => {
    if (navigator.userAgent?.indexOf('Mobi') > -1) {
      setIsMobileSize(true);
    } else if (window.innerWidth > 1080) {
      setIsMobileSize(false);
    } else if (window.innerWidth < 1080) {
      setIsMobileSize(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [isMobileSize, setIsMobileSize];
};

export default useMobileSize;
